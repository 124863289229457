:root {
  font-family: "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color-scheme: light dark;
  color: #444;
  background: #fafafa;
}

@media (prefers-color-scheme: dark) {
  :root {
    color: #fff;
    background: #1c1c1c;
  }
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  height: 100%;
}

#app > main {
  display: flex;
  margin: 0 auto;
  height: 100%;
  max-width: 1280px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 639px) {
  #app > main {
    margin: 0 2rem;
  }
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

input[type="checkbox"]:checked {
  background-color: red;
}

.disable-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
